@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@300;400;500;600;700;800;900&display=swap);

@font-face {
    font-family: "virus killer";
    src: url(../fonts/virusKiller.woff2) format("woff2");
    font-weight:normal;
}

@font-face {
    font-family:"terminal";
    src: url(../fonts/terminal.ttf) format("truetype");
    font-weight: normal;
}